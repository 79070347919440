<template>
  <div class="grzx">
    <div class="top">
      任务状态查询：
      <el-select v-model="value" @change="init(pageSize,0)" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="content">
      <el-table
        border
        row-key="id"
        class="el-tables"
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="name" label="任务名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{scope.row.name}}</div>
              <div slot="reference" class="name-wrapper">
                <div>{{scope.row.name}}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="任务状态">
          <template slot-scope="scope">
            <div v-if="scope.row.state==0">未启动</div>
            <div v-if="scope.row.state==1">已逾期</div>
            <div v-if="scope.row.state==2">加工中</div>
            <div v-if="scope.row.state==3">已完成</div>
            <div v-if="scope.row.state==4">已取消</div>
            <div v-if="scope.row.state==5">已违约</div>
          </template>
        </el-table-column>
        <el-table-column label="分配进度">
          <template slot-scope="scope">
            <span
              class="font"
              @click="jgjd(scope.row.id,scope.row.name,scope.row.jgFzrAndTaskId)"
            >{{scope.row.FPJD}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="加工进度">
          <template slot-scope="scope">
            <span
              class="font"
              @click="jgjd(scope.row.id,scope.row.name,scope.row.jgFzrAndTaskId)"
            >{{scope.row.JGJD}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="工期（截至）">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{scope.row.time}}天</div>
              <div
                class="text_cent"
                v-if="scope.row.state!=4&&scope.row.state!=0"
              >({{scope.row.endtime}})</div>
              <div slot="reference" class="name-wrapper">
                <div>{{scope.row.time}}天</div>
                <div v-if="scope.row.state!=4&&scope.row.state!=0">({{scope.row.endtime}})</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <span
              type="info"
              class="tuichubuttons1 buttons"
              plain
              disabled
              v-if="scope.row.state==3||scope.row.submit==1"
            >已提交</span>
            <span
              v-if="scope.row.state!=3&&scope.row.submit==0"
              class="buttons buttons1"
              @click="TiJiao(scope.row.state,scope.row.tasktype,scope.row.id,scope.row.userid,scope.row.name,scope.row.jgFzrAndTaskId,scope.row.id)"
            >提交</span>
            <span
              class="buttons buttons2"
              @click="deleted(scope.row.id,scope.row.tasktype,scope.row.state)"
            >删除</span>
            <el-button
              type="info"
              class="buttonss"
              plain
              disabled
              v-if="scope.row.state==0||scope.row.state==4"
            >查看</el-button>
            <span
              v-if="scope.row.state!=0&&scope.row.state!=4"
              class="buttons buttons3"
              @click="see(scope.row.id,scope.row.tasktype,scope.row.jgFzrAndTaskId)"
            >查看</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="prev">
        <!-- :hide-on-single-page="true" -->
        <!-- @prev-click="currentPage"
        @next-click="currentPage" -->
        <el-pagination
          @current-change="currentPage"
          background
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div class="zrwfp" v-if="jindu">
      <div class="zrwfp_top">
        <div class="zrwfp_title">{{rwmc}} —— 子任务分配表</div>
      </div>
      <el-table border row-key="id" class="el-table" :data="tableDatas">
        <el-table-column prop="childTaskName" label="子任务名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{ scope.row.childTaskName }}</div>
              <div slot="reference" class="name-wrapper">{{ scope.row.childTaskName }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.status==0">未领取</div>
            <div v-if="scope.row.status==1">未启动</div>
            <div v-if="scope.row.status==2">加工中</div>
            <div v-if="scope.row.status==3">审核中</div>
            <div v-if="scope.row.status==4">校对中</div>
            <div v-if="scope.row.status==5">已完成</div>
            <div v-if="scope.row.status==6">已逾期</div>
            <div v-if="scope.row.status==7">已违约</div>
          </template>
        </el-table-column>
        <el-table-column prop="jgUserName" label="加工者"></el-table-column>
        <el-table-column label="酬劳">
          <template slot-scope="scope">
            <div>{{scope.row.childTaskMoney}}知识币</div>
          </template>
        </el-table-column>
        <el-table-column prop="drawTime" label="领取时间"></el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <span class="buttons buttons4" v-if="scope.row.status==5&&scope.row.tasktype==1">已审核</span>
            <span
              class="buttons buttons4"
              v-if="scope.row.tasktype==1&&scope.row.status==4"
              @click="shenhe(scope.row.id,scope.row.tasktype,scope.row.status)"
            >审核</span>
            <span
              class="buttons buttons5"
              @click="tankuangs(scope.row.childTaskDay,scope.row.childTaskMoney,scope.row.taskId,scope.row.childTaskName,scope.row.id,scope.row.status)"
            >指定</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="red">此情况为：加工过程中出现（违约 ;逾期）现象，需要由负责 人指定给组内成员；</div> -->
    </div>
    <div class="heyueshu" v-if="tankuang">
      <div class="heyue_center">
        <div class="heyue_top">
          <span class="zrwfp_title1">{{zrw_name}}</span>
          <span class="yin1" @click="colse"></span>
        </div>
        <div class="zhengwen">
          <div>子任务加工工期：{{zrw_gq}}天</div>
          <div>任务酬劳：{{zrw_cl}}知识币</div>
          <div>
            指定人：
            <el-select class="select" @change="zdr" v-model="people" placeholder="请选择">
              <el-option
                v-for="item in Zhidr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="queding">
            <span class="buttons buttons5" @click="queding()">确定</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from "@/services/http.js";
import { dateType, YearMMdd } from "@/js/common/index.js";
import {
  DELETE,
  QUERY,
  UPDATES,
  UPDATE,
  QUERYED,
  INSERT
} from "@/services/dao.js";
export default {
  data() {
    return {
      tankuang: false,
      jindu: false, //是否显示子任务
      options: [
        {
          value: 6,
          label: "全部"
        },
        {
          value: 0,
          label: "未启动"
        },
        {
          value: 1,
          label: "已逾期"
        },
        {
          value: 2,
          label: "加工中"
        },
        {
          value: 3,
          label: "已完成"
        },
        {
          value: 4,
          label: "已取消"
        },
        {
          value: 5,
          label: "已违约"
        }
      ],
      value: 6,
      tableData: [], ///我负责的
      tableDatas: [], ///子任务
      Zhidr: [
        {
          value: "1",
          label: "张三"
        }
      ], //指定人
      people: "", //指定人
      rwmc: "", //任务名称
      pageSize: 5, //条数
      total: 0, //总条数
      pageNum: 0, ////当前页数
      zrw_name: "", //子任务名称
      zrw_gq: "", //子任务工期
      zrw_cl: "", //子任务酬劳
      zrw_id: "", //子任务ID
      zong_id: "", //总任务ID
      peoplename: "" //选中用户名
    };
  },
  created() {
    this.init(this.pageSize, 0);
  },
  methods: {
    ///审核
    shenhe(id, type, status) {
      if (status != 4) {
        ///已完成
        this.$message({
          message: "不可审核！",
          type: "warning"
        });
      } else {
        this.$router.push({
          path: "/entering",
          query: {
            id: id,
            type: 3 //任务负责人
          }
        });
      }
    },
    // 查看
    see(id, type, fzrid) {
      this.$router.push({
        path: "/Achievement",
        query: {
          id: id,
          type: type, //任务负责人
          fzrid: fzrid, //负责人ID
          state: 1 //0:发布者，1：负责人
        }
      });
    },

    async TiJiao(state, type, id, userid, name, jgFzrAndTaskId, jgPeperId) {
      if (state == 2) {
        //加工中
        let info = null;
        console.log('id',id,jgFzrAndTaskId,"fzrId");
        let data = await QUERYED(
          "post",
          "",
          "jgChildTask (where: {taskId  : {_eq:" +
            id +
            "},fzrId   : {_eq:" +
            jgFzrAndTaskId +
            '},status:{_eq:"5"}}){   id   }'
        );
        let data1 = await QUERYED(
          "post",
          "",
          "jgChildTask (where: {taskId  : {_eq:" +
            id +
            "},fzrId   : {_eq:" +
            jgFzrAndTaskId +
            "}}){     id   }"
        );
        if (data.data.jgChildTask.length == data1.data.jgChildTask.length) {
          //状态为已完成
          ///修改状态未已提交
          if (type == 0) {
            //////纸质化产品

            //查询是否关联lsli资源库
            let lsliCodeInfo = await QUERYED(
              "post",
              "",
              "jgPeper(where: {id:{_eq:" + jgPeperId + "}}){islicode id }"
            );
            if (!lsliCodeInfo.success) {
              this.$message({
                message: "获取数据失败，请重试",
                type: "success"
              });
              return;
            }
            let lsliCode = lsliCodeInfo.data.jgPeper[0].islicode;
            if (!lsliCode) {
              this.$message({
                message: "请先关联LSLi资源后在提交",
                type: "warning"
              });
              // this.$router.push({path:'/relation',query:{taskId:jgPeperId,type}})
              return;
            } else {
              info = await UPDATE(
                "post",
                "",
                "update_jgPeper(where: {id: {_eq: " +
                  id +
                  "}}, _set: {submit : 1,status :3}) { affected_rows  }"
              );
            }
            // if (info.data.update_jgPeper.affected_rows == 1) {
            //   this.$message({
            //     message: "已提交！",
            //     type: "success"
            //   });
            // } else {
            //   this.$message.error("提交失败！");
            // }
          } else if (type == 1) {
            ////数字化产品加工
            info = await UPDATE(
              "post",
              "",
              "update_jgDigitalPro(where: {id: {_eq: " +
                id +
                "}}, _set: {submit : 1}) { affected_rows  }"
            );
            // 通过ID查找type
            let type = await QUERYED(
              "post",
              "",
              "jgDigitalPro (where: {id   : {_eq:" + id + "}}){     type    }"
            );
            // 跳转课题二加工
            window.location.href =
              "http://www.izhihui.net/api/user/add/token?token=" +
              localStorage.getItem("Authorization") +
              "&targetUrl=http://www.izhihui.net/zz/#/home/work/" +
              id +
              "/inf?type=" +
              type.data.jgDigitalPro[0].type;
          } else if (type == 2) {
            /////词条
            // info = await UPDATE(
            //   "post",
            //   "",
            //   "update_jgEntryPro(where: {id: {_eq: " +
            //     id +
            //     "}}, _set: {submit : 1}) { affected_rows  }"
            // );

            ///如果是词条
            // if (type == 2) {
            ///课题三跳转加工
            window.location.href =
              "http://www.izhihui.net/eybak/#/achievement?token=" +
              // "http://106.2.224.58:8037/achievement?token=" +
              // "http://101.200.34.92/achievement?token=" +
              localStorage.getItem("Authorization") +
              "&taskId=" +
              id;
            // }
            // if (info.data.update_jgEntryPro.affected_rows == 1) {
            //   this.$message({
            //     message: "已提交！",
            //     type: "success"
            //   });
            // } else {
            //   this.$message.error("提交失败！");
            // }
          }

          //////给任务发布者发布消息通知 发布人ID:userid   taskType
          let fbz = await INSERT(
            "post",
            "",
            " insert_jgMessage(objects: {messageType:3,userId : " +
              userid +
              ",taskType :3,taskId :" +
              id +
              ",taskFatherId :" +
              id +
              ",userType: " +
              1 +
              ',taskName: "' +
              name +
              '",fzrId: "' +
              this.$store.state.userinfo.id +
              '",fzrName: "' +
              this.$store.state.userinfo.name +
              '",taskTime : "' +
              new Date().getTime() +
              '",taskTypeTow : ' +
              type +
              "}) { affected_rows  }"
          );
          if (fbz.data.insert_jgMessage.affected_rows == 1) {
            console.log("发送成功！");
            this.$message({
              message: "您已提交！",
              type: "success"
            });
            this.jindu = false;
            this.init(this.pageSize, this.pageNum);
          } else {
            this.$message.error("提交失败！");
          }
        } else {
          //状态不为已完成
          this.$message({
            message: "任务未完成哦！",
            type: "warning"
          });
        }
      } else {
        //状态不为已完成
        this.$message({
          message: "不可提交！",
          type: "warning"
        });
      }
    },


    // 初始化
    async init(limit, offset) {
      this.tableData = [];
      let info = null;
      if (this.value == 6) {
        ///查询全部

        info = await QUERYED(
          "post",
          "",
          "JGSearch(limit: " +
            limit +
            ", offset: " +
            offset +
            ", where: {FZ_delete : {_neq: 1},jgFzrAndTaskId : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){jgFzrAndTaskId taskname submit userid status totaldays endTime id totalmoney fzr_totalmoney  tasktype issueTime } JGSearch_aggregate (where: {jgFzrAndTaskId : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){     aggregate {       count     }   }"
        );
      } else {
        ///分类查询
        info = await QUERYED(
          "post",
          "",
          "JGSearch(limit: " +
            limit +
            ", offset: " +
            offset +
            
            ", where: {FZ_delete : {_neq: 1},jgFzrAndTaskId : {_eq:" +
            this.$store.state.userinfo.id +
            " },status : {_eq:" +
            this.value +
            " }}){jgFzrAndTaskId taskname submit userid  status totaldays endTime id totalmoney fzr_totalmoney  tasktype issueTime } JGSearch_aggregate (where: {jgFzrAndTaskId : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){     aggregate {       count     }   }"
        );
      }
      this.total = info.data.JGSearch_aggregate.aggregate.count; //
      for (let j = 0; j < info.data.JGSearch.length; j++) {
        let FPJD = "";
        let JGJD = "";
        if (info.data.JGSearch[j].jgFzrAndTaskId) {
          ///负责人存在
          //纸质化加工
          let zrw = await QUERYED(
            "post",
            "",
            "jgChildTask_aggregate (where: {taskId  : {_eq:" +
              info.data.JGSearch[j].id +
              "},fzrId   : {_eq:" +
              info.data.JGSearch[j].jgFzrAndTaskId +
              "}}){     aggregate {       count     }   }"
          );
          let fpwc = await QUERYED(
            "post",
            "",
            "jgChildTask_aggregate (where: {fzrId   : {_eq:" +
              info.data.JGSearch[j].jgFzrAndTaskId +
              "},taskId  : {_eq:" +
              info.data.JGSearch[j].id +
              ' },_not: {status: {_eq: "0"}}}){     aggregate {       count     }   } '
          );
          
          if (
            fpwc.data.jgChildTask_aggregate.aggregate.count == 0 &&
            zrw.data.jgChildTask_aggregate.aggregate.count == 0
          ) {
            FPJD = 0;
          } else {
            FPJD =
              (Number(fpwc.data.jgChildTask_aggregate.aggregate.count) /
                Number(zrw.data.jgChildTask_aggregate.aggregate.count)) *
              100;
          }

          if (info.data.JGSearch[j].tasktype == 0) {
            //纸质化加工
            let jgwc = await QUERYED(
              "post",
              "",
              "jgChildTask_aggregate (where: {taskId  : {_eq:" +
                info.data.JGSearch[j].id +
                ' },status   : {_eq:"5"},fzrId   : {_eq:' +
                info.data.JGSearch[j].jgFzrAndTaskId +
                "}}){     aggregate {       count     }   } "
            );
            if (
              jgwc.data.jgChildTask_aggregate.aggregate.count == 0 &&
              zrw.data.jgChildTask_aggregate.aggregate.count == 0
            ) {
              JGJD == 0;
            } else {
              // 加工进度
              JGJD =
                (Number(jgwc.data.jgChildTask_aggregate.aggregate.count) /
                  Number(zrw.data.jgChildTask_aggregate.aggregate.count)) *
                100;
            }
          }
          // else if (info.data.JGSearch[j].tasktype == 1) {
          //   //数字化
          //   await get("/common/SzcpProgressQuery", {
          //     taskId: info.data.JGSearch[j].id
          //   })
          //     .then(e => {
          //       JGJD = e.data; //课题二加工
          //       console.log("数字成功", JGJD);
          //     })
          //     .catch(function(error) {
          //       console.log("错误" + error);
          //     });
          // } else if (info.data.JGSearch[j].tasktype == 2) {
          //   //词条
          //   await post("/common/EntryProgressQuery", {
          //     taskId: info.data.JGSearch[j].id,
          //     type: 0
          //   })
          //     .then(e => {
          //       JGJD = e.data; //课题三加工
          //       console.log("成功成功", JGJD);
          //     })
          //     .catch(function(error) {
          //       console.log("错误" + error);
          //     });
          // }
        } else {
          FPJD = 0;
          JGJD = 0;
        }
        FPJD = Math.round(FPJD);
        JGJD = Math.round(JGJD);
        this.tableData.push({
          id: info.data.JGSearch[j].id,
          submit: info.data.JGSearch[j].submit, //提交状态
          state: info.data.JGSearch[j].status, //状态
          time: info.data.JGSearch[j].totaldays, //日期
          name: info.data.JGSearch[j].taskname, //任务名称
          tasktype: info.data.JGSearch[j].tasktype, //分类
          jgFzrAndTaskId: info.data.JGSearch[j].jgFzrAndTaskId, //负责人ID
          endtime: YearMMdd(info.data.JGSearch[j].endTime), //到期时间
          userid: info.data.JGSearch[j].userid, ////发布人ID
          FPJD: FPJD == "" ? 0 : FPJD,
          JGJD: JGJD == "" ? 0 : JGJD
        });
      }
      this.jindu = false; //关闭子任务
    },
    ///删除
    async deleted(id, type, state) {
      if (state == 2) {
        this.$message({
          message: "该任务状态为正在加工中方不可删除！",
          type: "warning"
        });
      } else if (state == 1) {
        this.$message({
          message: "该任务状态为已逾期不可删除！",
          type: "warning"
        });
      } else if (state == 0) {
        this.$message({
          message: "该任务状态为未启动不可删除！",
          type: "warning"
        });
      } else {
        let info = null;
        if (type == 0) {
          //////纸质化产品
          // info = await DELETE(
          //   "post",
          //   "",
          //   "delete_jgPeper(where: {id : {_eq: " +
          //     id +
          //     "}, tasktype : {_eq: " +
          //     type +
          //     "}}) {   affected_rows  }"
          // );
          info = await UPDATE(
            "post",
            "",
            "update_jgPeper(where: {id: {_eq: " +
              id +
              "}, tasktype : {_eq: " +
              type +
              "}}, _set: {FB_delete  : 1}) { affected_rows  }"
          );
          if (info.data.update_jgPeper.affected_rows == 1) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.init(this.pageSize, this.pageSize * this.pageNum); //刷新
          } else {
            this.$message.error("删除失败！");
          }
        } else if (type == 1) {
          ////数字化产品加工
          // info = await DELETE(
          //   "post",
          //   "",
          //   "delete_jgDigitalPro(where: {id : {_eq: " +
          //     id +
          //     "}, tasktype : {_eq: " +
          //     type +
          //     "}}) {   affected_rows  }"
          // );
          info = await UPDATE(
            "post",
            "",
            "update_jgDigitalPro(where: {id: {_eq: " +
              id +
              "}, tasktype : {_eq: " +
              type +
              "}}, _set: {FB_delete  : 1}) { affected_rows  }"
          );
          if (info.data.update_jgDigitalPro.affected_rows == 1) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.init(this.pageSize, this.pageSize * this.pageNum); //刷新
          } else {
            this.$message.error("删除失败！");
          }
        } else if (type == 2) {
          ///// 词条
          // info = await DELETE(
          //   "post",
          //   "",
          //   "delete_jgEntryPro(where: {id : {_eq: " +
          //     id +
          //     "}, tasktype : {_eq: " +
          //     type +
          //     "}}) {   affected_rows  }"
          // );
          info = await UPDATE(
            "post",
            "",
            "update_jgEntryPro(where: {id: {_eq: " +
              id +
              "}, tasktype : {_eq: " +
              type +
              "}}, _set: {FB_delete  : 1}) { affected_rows  }"
          );
          if (info.data.update_jgEntryPro.affected_rows == 1) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.init(this.pageSize, this.pageSize * this.pageNum); //刷新
          } else {
            this.$message.error("删除失败！");
          }
        }
      }
    },

    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    ////查看子任务
    async jgjd(id, name, jgFzrAndTaskId) {
      this.tableDatas = []; //清空
      this.jindu = true;
      this.rwmc = name; ///任务名称
      if (jgFzrAndTaskId) {
        let info = await QUERYED(
          "post",
          "",
          "jgChildTask(where: {taskId  : {_eq:" +
            id +
            " },fzrId   : {_eq:" +
            jgFzrAndTaskId +
            " }}){id childTaskDay taskType taskId taskName childTaskMoney jgUserName fzrName status  drawTime childTaskName } jgChildTask_aggregate (where: {taskId : {_eq:" +
            id +
            " },fzrId   : {_eq:" +
            jgFzrAndTaskId +
            " }}){     aggregate {       count     }   }"
        );
        console.log("info", info.data.jgChildTask);
        for (let j = 0; j < info.data.jgChildTask.length; j++) {
          this.tableDatas.push({
            id: info.data.jgChildTask[j].id,
            childTaskMoney: info.data.jgChildTask[j].childTaskMoney,
            jgUserName: info.data.jgChildTask[j].jgUserName,
            status: info.data.jgChildTask[j].status,
            drawTime:
              info.data.jgChildTask[j].drawTime == null
                ? ""
                : YearMMdd(Number(info.data.jgChildTask[j].drawTime)),
            childTaskName: info.data.jgChildTask[j].childTaskName,
            taskId: info.data.jgChildTask[j].taskId, //总任务ID
            tasktype: info.data.jgChildTask[j].taskType,
            childTaskDay: info.data.jgChildTask[j].childTaskDay ///工期
          });
        }
      }
    },
    //关闭
    colse() {
      this.tankuang = false; //隐藏
    },
    async tankuangs(childTaskDay, childTaskMoney, taskId, name, id, status) {
      if (status != 7) {
        ///以违约
        this.$message({
          message: "该任务不需重新指定负责人！",
          type: "warning"
        });
      } else {
        this.Zhidr = [];
        this.tankuang = !this.tankuang; //隐藏
        this.zrw_name = name; //名称
        this.zrw_gq = childTaskDay; //工期
        this.zrw_cl = childTaskMoney; //酬劳
        this.zrw_id = id; //id
        this.zong_id = taskId; //总任务
        let info = await QUERYED(
          "post",
          "",
          "jgChildTask(distinct_on: jgUserId,where: {taskId  : {_eq:" +
            taskId +
            ' },status:{_neq:"' +
            7 +
            '"}}){jgUserId  jgUserName }'
        );
        for (let j = 0; j < info.data.jgChildTask.length; j++) {
          if (info.data.jgChildTask[j].jgUserName) {
            this.Zhidr.push({
              value: info.data.jgChildTask[j].jgUserId,
              label: info.data.jgChildTask[j].jgUserName
            }); ///指定人
          }
        }
      }
      // this.Zhidr = info.data.jgChildTask; //指定人
      // console.log("jgChildTask",info.data.jgChildTask)
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    // 确定
    async queding() {
      if (this.people == "") {
        this.$message({
          message: "请选择指定人！",
          type: "warning"
        });
      } else {
        let info = await UPDATE(
          "post",
          "",
          "update_jgChildTask(where: {id: {_eq: " +
            this.zrw_id +
            "}}, _set: {jgUserId: " +
            this.people +
            ',jgUserName : "' +
            this.peoplename +
            '",status :"2",drawTime:"' +
            new Date().getTime() +
            '"}) { affected_rows  }'
        );
        if (info.data.update_jgChildTask.affected_rows == 1) {
          this.$message({
            message: "指定成功！",
            type: "success"
          });
          this.tankuang = false; //隐藏
          this.jgjd(this.zong_id, this.zrw_name);
        } else {
          this.$message.error("指定失败！");
        }
      }
    },
    zdr(val) {
      console.log(val);
      this.peoplename = val
        ? this.Zhidr.find(ele => ele.value === val).label
        : "";
      console.log(val ? this.Zhidr.find(ele => ele.value === val).label : "");
    }
  }
};
</script>
<style scoped>
.el-tables {
  width: 100%;
  /* border: 1px solid #000000; */
  margin-top: 30px;
}
.el-select {
  /* margin-top: 20px; */
}
.top {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  padding-top: 30px;
}
::v-deep .el-table .warning-row {
  /* background: rgb(235, 233, 229); */
}
::v-deep .el-table .success-row {
  /* background: #c4c7c3; */
}
/* ::v-deep table {
  border-left: 1px solid #000000;
} */
/* ::v-deep thead
{
background-color: #b92424;
} */
/* ::v-deep table thead tr th {
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
} */
/* ::v-deep tbody tr td {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
} */
/* ::v-deep .el-table th.is-leaf {
  border-bottom: 1px solid #000000;
} */
.el-table::before {
  z-index: auto;
}
.buttons {
  width: 0.35rem;
  height: 27px;
  line-height: 27px;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.buttons1 {
  background-color: #0084ff;
  color: #ffffff;
  border: 1px solid #3e94f7;
}
.buttons2 {
  background-color: #f8d5d8;
  color: #ba0f2e;
  margin-left: 5px;
  border: 1px solid #f0a7ad;
}
.buttons3 {
  background-color: #d6edfb;
  color: #000502;
  margin-left: 5px;
  border: 1px solid #a5d1fa;
  /* border:1px solid #0068B7; */
}
.buttons4 {
  background-color: #ffffff;
  color: #0084ff;
  /* margin-left: 5px; */
  border: 1px solid #a5d1fa;
  margin-left: 5px;
  /* border:1px solid #0068B7; */
}
.buttons5 {
  background-color: #0084ff;
  color: #feffff;
  margin-left: 5px;
  /* margin-left: 5px; */
  border: 1px solid #3e94f7;
  /* border:1px solid #0068B7; */
}
.content {
  width: 100%;
  /* border: 1px solid red; */
}
.prev {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.zrwfp {
  width: 760px;
  /* border: 1px solid red; */
  margin: auto;
  margin-top: 50px;
}
.zrwfp_top {
  width: 758px;
  height: 50px;
  background-color: #ffffff;
  border-right: 1px solid #e4e7ed;
  border-left: 1px solid #e4e7ed;
  border-top: 1px solid #e4e7ed;
  padding: 20px 0px 0px 0px;
}
.zrwfp_top > div {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 27px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  /* padding: 0px 10px 0px 12px; */
}
.zrwfp_title {
  font-weight: bold;
  color: #000000;
}
.zrwfp_title1 {
  /* border: 1px solid red; */
  width: 335px;
  font-size: 15px;
  display: inline-block;
  color: #000000;
  text-align: center;
}
.zrwfp_fzr {
  font-weight: 400;
  color: #3e94f7;
}
.red {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c10015;
  line-height: 52px;
  text-align: center;
}
.font {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-decoration: underline;
  color: #3e94f7;
}
/* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
.zhengwen {
  /* border: 1px solid red; */
  /* width: 480px;
  height: 490px; */
  width: 371px;
  height: 170px;
  display: inline-block;
  padding: 20px;
  background-color: #ffffff;
}
.zhengwen >>> .select {
  width: 130px;
  height: 10px;
}
.zhengwen > div {
  line-height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  /* border: 1px solid red; */
  width: 200px;
  /* margin: auto; */
  margin-left: 110px;
}
.heyue_top {
  width: 100%;
  /* height: 18px; */
  padding: 10px 0px 10px 0px;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: right;
  border-bottom: 1px solid #434343;
  background: #eeeeee;
}
.heyue_center {
  width: 411px;
  height: 260px;
  background: #ffffff;
  background-color: #e0e0e0;
  border: 1px solid #434343;
  /* padding: 10px; */
}
.heyueshu {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 23px 23px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
.queding {
  margin-top: 15px;
  /* text-align: center; */
  padding-left: 30px;
}
.grzx >>> .el-table__header {
  /* border: 2px solid red; */
  width: 100% !important;
}
.grzx >>> .el-table__body {
  width: 100% !important;
}
.el-table {
  width: 100%;
}
/* 查看按钮 */
.buttonss {
  /* margin-left: 5px; */
  width: 60px;
  height: 30px;
  /* line-height: 28px; */
  text-align: center;
  position: relative;
  top: 4px;
  background-color: #1c9beb;
  color: #000502;
  border: 1px solid #a5d1fa;
}
::v-deep .buttonss > span {
  position: relative;
  left: -11.5px;
  top: -5px;
  font-size: 14px;
}
.text_cent {
  text-align: center;
  /* border: 1px solid red; */
}
.tuichubuttons1 {
  background-color: #f5f4f4;
  color: #6e6e6e;
  border: 1px solid #afafaf;
  cursor:no-drop !important;
}
</style>